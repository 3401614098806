import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/layout/seo"
import Section from "../../components/section"
import Container from "../../components/container"
import Box from "../../components/box"

const LegalNoticePage = ({ _path, data }) => (
  <Layout path={"/impressum"}>
    <SEO title="Impressum" />
    <HeaderSection />
    <CompanyInfoSection />
    <ImageRightsSection />
    <DisclaimerSection />
    <ReferenceSection />
  </Layout>
)

const HeaderSection = () => (
  <Section id={"HeaderSection"}>
    <Container>
      <Box padding={"full"} background={"red"}>
        <h1>Impressum</h1>
      </Box>
    </Container>
  </Section>
)

const CompanyInfoSection = () => (
  <Section id={"CompanyInfoSection"} className={"article"}>
    <Container justify={"left"}>
      <Box background={"gray-light"} padding={"full"}>
        <p><strong>Red Oak Consulting &amp; Services for IT GmbH &amp; Co. KG</strong><br />
          Westbahnhof 11<br />
          38118 Braunschweig </p>
        <span>E-Mail: <a href="mailto:kontakt@red-oak-consulting.com">kontakt@red-oak-consulting.com</a></span>
      </Box>
    </Container>
    <Container>
      <Box padding={"horizontal"} alignText={"left"}>
        <h2>Vertreten durch</h2>
        <p>Komplementär Red Oak Management GmbH<br />
          Geschäftsführer Matthias Geske<br />
          Geschäftsführer Michael Blank</p>
        <h2>Registereintrag</h2>
        <p>Eingetragen im Handelsregister.<br />
          Registergericht: Braunschweig<br />
          Registernummer: HRA202077  </p>
        <h2>Umsatzsteuer-ID</h2>
        <p>
          Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz: DE314579871
        </p>
      </Box>
    </Container>
  </Section>
)

const ImageRightsSection = () => (
  <Section id={"ImageRightsSection"} className={"article"}>
    <Container>
      <Box padding={"horizontal"} alignText={"left"}>
        <h2>Bildrechte</h2>
        <p>
          Netzlink<br />
          Good Visuals Only<br />
          Julia Bajohr
        </p>
      </Box>
    </Container>
  </Section>
)

const DisclaimerSection = () => (
  <Section id={"DisclaimerSection"} className={"article"}>
    <Container>
      <Box padding={"horizontal"} alignText={"left"}>
        <h3>Disclaimer – rechtliche Hinweise</h3>
        <h2>§ 1 Warnhinweis zu Inhalten</h2>
        <p>
          Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit größtmöglicher Sorgfalt erstellt.
          Der Anbieter dieser Webseite übernimmt jedoch keine Gewähr für die Richtigkeit und Aktualität der bereitgestellten
          kostenlosen und frei zugänglichen journalistischen Ratgeber und Nachrichten. Namentlich gekennzeichnete Beiträge
          geben die Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters wieder. Allein durch den
          Aufruf der kostenlosen und frei zugänglichen Inhalte kommt keinerlei Vertragsverhältnis zwischen dem Nutzer
          und dem Anbieter zustande, insoweit fehlt es am Rechtsbindungswillen des Anbieters.
        </p>
        <h2>§ 2 Externe Links</h2>
        <p>
          Diese Website enthält Verknüpfungen zu Websites Dritter ("externe Links"). Diese Websites unterliegen der
          Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen Links die
          fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine
          Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung
          und auf die Inhalte der verknüpften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der
          Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige Kontrolle der
          externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis
          von Rechtsverstößen werden jedoch derartige externe Links unverzüglich gelöscht.
        </p>
        <h2>§ 3 Urheber- und Leistungsschutzrechte</h2>
        <p>
          Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht.
          Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen
          schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt insbesondere für Vervielfältigung,
          Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder
          anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet.
          Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet
          und strafbar. Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten und nicht
          kommerziellen Gebrauch ist erlaubt.
        </p>
        <p>
          Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig.
        </p>
        <h2>§ 4 Besondere Nutzungsbedingungen</h2>
        <p>
          Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Paragraphen abweichen,
          wird an entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall
          die besonderen Nutzungsbedingungen.
        </p>
      </Box>
    </Container>
  </Section>
)

const ReferenceSection = () => (
  <Section id={"ReferenceSection"} className={"article"}>
    <Container>
      <Box padding={"horizontal"} alignText={"left"}>
        <p>Quelle: <a href="https://www.juraforum.de/impressum-generator/">Impressum Muster von JuraForum.de</a></p>
      </Box>
    </Container>
  </Section>
)

export default LegalNoticePage

export const query = graphql`
    query LegalNoticeQuery {
        site {
            siteMetadata {
                title
            }
        }
    }
`